h1.judul-mn {
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px;
    color: rgb(0, 0, 0);
}

.Menu-container {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 80px;
    width: 100%;
    height: 70vh;
}

.IPA-contaiener, .IPS-container, .MTK-container{
    height: auto;
    width: auto;
}

.card1, .card2, .card3 {
    width: 220px;
    height: 395px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border: 2px solid transparent;
    margin: 0px;
}

.card1:hover, .card2:hover, .card3:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), 0px 0px 20px 10px rgba(255, 255, 255, 10);
    border: 2px solid rgba(34, 151, 169, 1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.card-visible {
    transform: scale(1.1);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4);
    border: 2px solid rgba(34, 151, 169, 1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

@media (max-width: 768px) {
    .Menu-container {
        display: flex;
        flex-direction: row;
        height: auto;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        gap: 30px;
        padding: 20px;
        justify-content: flex-start;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
}

    .card1, .card2, .card3 {
        min-width: 250px; 
        height: auto;
        scroll-snap-align: center; 
    }

    .card1:active, .card2:active, .card3:active {
        transform: scale(1.03);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    }

    .Menu-container::-webkit-scrollbar {
        display: none; 
    }
}
