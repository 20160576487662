body {
    margin: 0;
    padding: 0;
    background-color: rgb(23, 129, 145);
    font-family: Arial, sans-serif;
}

.container-fluid, .container {
    padding: 0;
}

.judul-ct{
    font-size: 40px;
    color: black;
}

.container-contact-us {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
    text-align: center;
    flex-wrap: wrap;
}

.whatsapp, .mediasosial, .other {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    min-width: 200px;
}

.icon {
    margin-bottom: 10px;
}

.icon img {
    width: 40px;
    height: 40px;
}

.ic {
    display: flex; 
    align-items: center; 
    text-align: center;
    gap: 15px; 
}

.ic img {
    width: 30px;
    height: 30px;
    top: 20px;
}

a {
    text-decoration: none;
    display: inline-block;
    color: black;
    font-size: 18px;
    margin: 5px 0;
    font-weight: bold;
    text-align: center;
    transition: color 0.3s ease;
}

a:hover {
    color: rgb(23, 180, 145);
}

.icon-map, .icon-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.icon-map img, .icon-email img {
    width: 30px;
    height: 30px;
}

h1 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    color: white;
}


@media (max-width: 768px) {
    .container-contact-us {
        flex-direction: column;
    }

    h1 {
        font-size: 24px;
    }

    a {
        font-size: 20px;
    }
}