.arm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto; 
    text-align: center;
    margin: 90px 0; 
  }
  
  .katalok-arm,
  .beli {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px; 
  }
  
  .katalog-img,
  .beli-img {
    width: 90%; 
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .beli-img:hover {
    transform: scale(1.1); 
    cursor: pointer;
  }
  
  /* Responsif untuk layar kecil */
  @media (max-width: 768px) {
    .arm-container {
      margin: 20px 0; 
      
    }
  
    .katalog-img{
      width: 130vw; 
      height: auto;
    }

    .beli-img {
        width: 80%;
    }
  }
  

  .beli-img {
    max-width: 90%; /* Membuat gambar responsif */
    height: auto;
    animation: float 2s ease-in-out infinite; /* Animasi naik turun */
    transition: transform 0.3s ease; /* Efek hover */
  }
  
  .beli-img:hover {
    transform: scale(1.1); /* Memperbesar gambar saat di-hover */
    cursor: pointer;
  }
  
  /* Animasi naik turun */
  @keyframes float {
    0% {
      transform: translateY(0); /* Posisi awal */
    }
    50% {
      transform: translateY(-10px); /* Naik */
    }
    100% {
      transform: translateY(0); /* Kembali ke posisi awal */
    }
  }
  