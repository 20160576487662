h1, h2{
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

  p, h3{
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    scroll-behavior: smooth;
    box-sizing: border-box;
  }
  

