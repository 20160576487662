.img-fluid {
    max-width: 100%;
    height: auto;
    transition: transform 0.2s ease-in-out;
}

.judul-os{
    color: black;
    font-size: 40px;
}

.img-fluid:hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
}

.button-sponsor {
    display: block;
    text-align: center;
    margin: 20px auto 0 auto;
    font-size: 16px;
    bottom: 2vh;
    padding: 20px;
  }

@media (min-width: 576px) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 768px) {
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
}


.modal-body h4 {
    font-size: 1.5rem;
}

.modal-body p {
    font-size: 1rem;
}


.modal-footer .btn {
    margin-right: 10px;
}


@media (max-width: 576px) {
    .modal-footer .btn {
        width: 100%;
        margin-bottom: 10px;
    }
}


img {
    cursor: pointer;
}
