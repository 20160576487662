.notfound-container {
    background-image: url('../img/background/Parallax-01.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.notfound-content {
    max-width: 550px;
    background-color: rgb(53, 250, 243, 0.9);
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.notfound-content h1 {
    font-size: 80px;
    color: #ff4040;
    margin: 0;
}

.notfound-content h2 {
    font-size: 24px;
    color: #333;
    margin: 10px 0;
}

.notfound-content p {
    font-size: 16px;
    color: #666;
    margin: 10px 0 20px;
}

.back-home {
    display: inline-block;
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.back-home:hover {
    background-color: #0056b3;
}

.notfound-image img {
    max-width: 50%;
    height: auto;
    margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .notfound-content {
        padding: 20px;
    }

    .notfound-content h1 {
        font-size: 60px;
    }

    .notfound-content h2 {
        font-size: 20px;
    }

    .notfound-content p {
        font-size: 14px;
    }

    .back-home {
        padding: 10px 16px;
    }
}
